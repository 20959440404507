<template>
	<div class="admin_manage">
		<el-card>
			<el-row class="left">
				<el-col :span="24">
					<el-form :inline="true" class="demo-form-inline">
						<el-form-item label="账号:">
							<el-input v-model="account" placeholder="请输入账号" clearable></el-input>
						</el-form-item>
						<el-form-item label="姓名:">
							<el-input v-model="admin_name" placeholder="请输入姓名" clearable></el-input>
						</el-form-item>
						<el-form-item>
							<el-button type="primary" @click="search" icon="el-icon-search">搜索</el-button>
							<el-button type="default" @click="reset" icon="el-icon-refresh">重置</el-button>
						</el-form-item>
					</el-form>
				</el-col>
			</el-row>
			<el-row class="left mb30">
				<el-col :span="24">
					<el-button type="primary" size="mini" icon="el-icon-plus" @click="add">添加</el-button>
					<el-button type="danger" size="mini" icon="el-icon-delete" @click="removeAll">删除</el-button>
				</el-col>
			</el-row>
			<el-table border :data="adminList" @select="select" @select-all="selectAll" style="width: 100%" :header-cell-style="{ background: '#eef1f6', color: '#606266' }">
				<el-table-column fixed type="selection"></el-table-column>
				<el-table-column label="ID" prop="admin_id" width="60"></el-table-column>
				<el-table-column :show-overflow-tooltip="true" label="管理员账号" prop="account" width="100px"></el-table-column>
				<el-table-column :show-overflow-tooltip="true" label="管理员姓名" prop="admin_name" width="100px"></el-table-column>
				<el-table-column label="头像">
					<template slot-scope="scope">
						<div class="cell el-tooltip" style="width: 58px;">
							<span class="el-avatar el-avatar--square" style="height: 25px; width: 25px; line-height: 25px;">
								<el-image :src="scope.row.admin_logo" fit="fit"></el-image>
							</span>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="sex" label="性别">
					<template slot-scope="scope">
						<el-tag :type="scope.row.sex === 1 ? 'primary' : 'success'" disable-transitions>{{scope.row.sex_name}}</el-tag>
					</template>
				</el-table-column>
				<el-table-column label="联系方式" prop="phone" width="130"></el-table-column>
				<el-table-column :show-overflow-tooltip="true" label="角色" width="180">
					<template slot-scope="scope">
						<el-tag type="primary" disable-transitions v-for="(item,index) in scope.row.role_list" :key="index" style="margin-left: 5px;">{{item}}&nbsp;&nbsp;</el-tag>
					</template>
				</el-table-column>
				<el-table-column label="部门" width="120">
					<template slot-scope="scope" v-if="scope.row.dept_name">
						<el-tag type="primary" disable-transitions style="margin-left: 5px;">{{scope.row.dept_name}}</el-tag>
					</template>
				</el-table-column>
				<el-table-column :show-overflow-tooltip="true" label="运营城市" width="180">
					<template slot-scope="scope">
						<el-tag type="primary" disable-transitions v-for="(item,index) in scope.row.city_list" :key="index" style="margin-left: 2px;">{{item}}&nbsp;&nbsp;</el-tag>
					</template>
				</el-table-column>
				<el-table-column label="职级状态">
					<template slot-scope="scope">
						<el-switch v-model="scope.row.admin_status" @change="setAdminStatus(scope.row)"></el-switch>
					</template>
				</el-table-column>
				<el-table-column label="创建时间" prop="create_time" width="150"></el-table-column>
				<el-table-column label="更新时间" prop="update_time" width="150"></el-table-column>
				<el-table-column label="操作" fixed="right" width="250">
					<template slot-scope="scope">
						<div>
							<el-button type="primary" size="mini" @click="update(scope.row)">修改</el-button>
							<el-button type="danger" size="mini" @click="remove(scope.row)">删除</el-button>
							<el-button type="warning" size="mini" @click="resetPwd(scope.row)">重置密码</el-button>
						</div>
					</template>
				</el-table-column>
			</el-table>
			<Pagination :queryInfo="queryInfo" :total="total" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange"></Pagination>
		</el-card>
		<!-- 添加/更新 -->
		<el-dialog :title="title" width="60%" top="5vh" :visible.sync="addVisible">
			<el-form ref="addRef" :model="addForm" :rules="addRules" label-width="100px" class="left">
				<el-row>
					<el-col :span="24">
						<el-form-item label="头像:">
							<div class="previewUpload">
								<div v-if="addForm.admin_logo!=''" class="previewItem">
									<img :src="addForm.admin_logo" alt="">
									<i class="el-icon-close delIcon" @click="delAdminLogo"></i>
								</div>
								<div class="previewItems" v-else>
									<el-upload ref="upload" style="width:100px" list-type="picture" action="https://jsonplaceholder.typicode.com/posts/" accept="image/*" :auto-upload="false" :show-file-list="false" :multiple=true :on-change="handlerChange">
										<i class="el-icon-plus previewadd"></i>
									</el-upload>
								</div>
							</div>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="姓名:" prop="admin_name">
							<el-input v-model="addForm.admin_name" placeholder="请输入姓名"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="性别:" prop="sex">
							<el-radio v-model="addForm.sex" label="1">男</el-radio>
							<el-radio v-model="addForm.sex" label="2">女</el-radio>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="账号:" prop="account">
							<el-input v-model="addForm.account" maxlength="10" placeholder="请输入账号" :disabled="addForm.admin_id?'disabled':false"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="密码:" prop="password">
							<el-input v-model="addForm.password" placeholder="请输入密码" :disabled="addForm.admin_id?'disabled':false"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="联系方式:" prop="phone">
							<el-input v-model="addForm.phone" placeholder="请输入联系方式"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="邮箱:" prop="email">
							<el-input v-model="addForm.email" placeholder="请输入邮箱"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="角色:" prop="role_id">
							<el-select v-model="addForm.role_id" multiple placeholder="请选择" style="width: 100%;">
								<el-option v-for="item in role_list" :key="item.value" :label="item.label" :value="item.value">
								</el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="12">
						<el-form-item label="部门:" prop="dept_id">
							<treeselect v-model="addForm.dept_id" :options="deptList" :defaultExpandLevel="Infinity" :normalizer="normalizer" :show-count="true" placeholder="请选择" />
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label="运营城市" prop="city_id" style="text-align: left;">
							<treeselect v-model="addForm.city_id" :options="city_list" :defaultExpandLevel="Infinity" :normalizer="normalizer_city" :show-count="true" placeholder="请选择运营城市" />
						</el-form-item>
					</el-col>
					<el-col :span="24">
						<el-form-item label="个人简介:" prop="remark">
							<el-input type="textarea" :rows="4" v-model="addForm.remark" placeholder="请输入个人简介"></el-input>
						</el-form-item>
					</el-col>
				</el-row>
			</el-form>
			<div>
				<el-button type="default" @click="addClose">取消</el-button>
				<el-button type="primary" @click="addSub">保存</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	let that;
	let timer = null;
	import axios from 'axios'
	import Treeselect from "@riophae/vue-treeselect";
	import "@riophae/vue-treeselect/dist/vue-treeselect.css";
	import Pagination from '@/components/Pagination/Pagination.vue'
	export default {
		components: {
			Treeselect,Pagination
		},
		data() {
			return {
				queryInfo: {
					pagenum: 1,
					pagesize: 10,
				},
				title: '添加角色',
				total: 0,
				account: '',
				admin_name: '',
				role_list: [],
				adminList: [],
				chooseList: [],
				addVisible: false,
				fenpeiVisible: false,
				fileData: {},
				addForm: {
					admin_id: '',
					admin_logo: '',
					admin_name: '',
					sex: '1',
					phone: '',
					account: '',
					password: '',
					email: '',
					remark: '',
					role_id: '',
					dept_id: null,
					city_id: null,
				},
				addRules: {
					admin_name: [{
						required: true,
						message: '请输入姓名',
						trigger: 'blur'
					}],
					account: [{
						required: true,
						message: '请输入账号',
						trigger: 'blur'
					}],
				},
				city_list: [],
				deptList: [],
				
			}
		},
		created() {
			that = this;
			if(!this.$store.state.userInfo) {
				// 防止未登录
				this.$router.push({
					path: '/login'
				})
			} else {
				this.initData();
				this.getCityList();
				this.getDeptList();
				this.getAdminList();
			}
		},
		methods: {
			initData() {
				var url = 'admin/init_data';
				let params = {};
				this.fd_post(url, params).then((res) => {
					if(res.status) {
						that.role_list = res.role_list;
					} else {
						that.$message.error(res.msg);
					}
				}).catch((err) => {
					that.$message.error('网络错误');
				});
			},
			getCityList(){
	  			var url = 'city/city_list';
				let params = {
					is_del: 1
				};
				this.fd_post(url, params).then((res) => {
					if(res.status) {
						that.city_list = res.list;
					} else {
						that.$message.error(res.msg);
					}
				}).catch((err) => {
					that.$message.error('网络错误');
				});
	  		},
			getDeptList() {
				var url = 'dept/dept_list';
				let params = {};
				this.fd_post(url, params).then((res) => {
					if(res.status) {
						that.deptList = res.list;
					} else {
						that.$message.error(res.msg);
					}
				}).catch((err) => {
					that.$message.error('网络错误');
				});
			},
			getAdminList() {
				var url = 'admin/admin_list';
				let params = {
					account: this.account,
					admin_name: this.admin_name,
					size: this.queryInfo.pagesize,
					page: this.queryInfo.pagenum,
				};
				this.fd_post(url, params).then((res) => {
					if(res.status) {
						that.total = res.total;
						that.adminList = res.list;
					} else {
						that.$message.error(res.msg);
					}
				}).catch((err) => {
					that.$message.error('网络错误');
				});
			},
			//转换菜单数据结构
			normalizer_city(node) {
				if(node.children && !node.children.length) {
					delete node.children;
				}
				return {
					id: node.city_id,
					label: node.city_name,
					children: node.children,
				};
			},
			//查询
			search() {
				this.getAdminList();
			},
			// 重置
			reset() {
				this.role_name = "";
				this.getAdminList();
			},
			//页数改变
			handleSizeChange(newSize) {
				this.queryInfo.pagenum = 1
				this.queryInfo.pagesize = newSize
				this.getAdminList();
			},
			//页码改变
			handleCurrentChange(newPage) {
				this.queryInfo.pagenum = newPage
				this.getAdminList();
			},
			//单选
			select(selection, row) {
				this.chooseList = selection
				console.log(this.chooseList);
			},
			//全选
			selectAll(selection) {
				this.chooseList = selection
				console.log(this.chooseList);
			},
			//添加弹窗打开
			add() {
				this.title = '添加管理员';
				this.addForm = {
					admin_id: '',
					admin_logo: [],
					admin_name: '',
					sex: '1',
					phone: '',
					account: '',
					password: '',
					email: '',
					remark: '',
					role_id: '',
					dept_id: null,
					city_id: null,
				};
				this.addVisible = true;
			},
			update(item){
				this.title = '编辑管理员';
				this.addForm = item;
				this.addVisible = true;
			},
			//关闭添加弹窗
			addClose() {
				this.$refs.addRef.resetFields()
				this.addForm.role_desc = ''
				this.addVisible = false
			},
			//确认添加
			addSub() {
				this.$refs.addRef.validate(value => {
					if(!value) return
					var url = 'admin/addOrUpdate';
					var formData = new FormData();
					formData.append("admin_id", this.addForm.admin_id);
					formData.append("admin_logo", this.addForm.admin_logo);
					formData.append("admin_name", this.addForm.admin_name);
					formData.append("sex", this.addForm.sex);
					formData.append("account", this.addForm.account);
					formData.append("password", this.addForm.password);
					formData.append("phone", this.addForm.phone);
					formData.append("email", this.addForm.email);
					formData.append("role_id", this.addForm.role_id);
					formData.append("dept_id", this.addForm.dept_id);
					formData.append("city_id", this.addForm.city_id);
					formData.append("remark", this.addForm.remark);
					//如果存在文件
					if(this.fileData.raw) {
						formData.append("file", this.fileData.raw, this.fileData.name);
					}
					console.log(this.addForm.city_id);
					if(timer != null) {
						clearTimeout(timer);
					}
					timer = setTimeout(() => {
						axios({
							url: url,
							headers: {
								"Content-Type": "multipart/form-data",
							},
							method: "post",
							data: formData,
						}).then(res => {
							this.addVisible = false
							if(res.data.status) {
								that.$message.success('操作成功');
								that.getAdminList();
							} else {
								that.$message.error(res.data.msg);
							}
						}).catch(error => {
							this.addVisible = false
							that.$message.error('网络错误');
						})
					}, 300);
				})
			},
			//上传
			handlerChange(file) {
				this.addForm.admin_logo = URL.createObjectURL(file.raw);
				this.fileData = file;
			},
			//设置职级状态
			setAdminStatus(item){
				if(timer != null){clearTimeout(timer);}
				timer = setTimeout(() => {
					var url = 'admin/setAdminStatus';
		        	let params = {
		        		admin_id: item.admin_id
		        	};
					that.fd_post(url, params).then((res) => {
		        		if(res.status){
		        			that.$message.success("操作成功");
		        		}else{
		        			that.$message.error(res.msg);
		        		}
			        }).catch((err) => {
			            that.$message.error('网络错误');
			        });
				},300);
			},
			//多选删除
			removeAll() {
				if(this.chooseList.length == 0) return
				this.$confirm('此操作将永久删除选中的数据, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					if(timer != null){clearTimeout(timer);}
					timer = setTimeout(() => {
						var url = 'admin/deleteAdmin';
				    	let params = {
				    		admin_type: 1, 
				    		admin_id: JSON.stringify(that.chooseList)
				    	};
						that.fd_post(url, params).then((res) => {
				    		if(res.status){
				    			that.$message.success("操作成功");
				    			that.getAdminList();
				    		}else{
				    			that.$message.error(res.msg);
				    		}
				        }).catch((err) => {
				            that.$message.error('网络错误');
				        });
					},300);
				}).catch(() => {
					this.$message.info('已取消删除');
				});
			},
			//删除
			remove(item) {
				this.$confirm('你确定要删除吗,此操作将永久删除该条数据?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					if(timer != null){clearTimeout(timer);}
					timer = setTimeout(() => {
						var url = 'admin/deleteAdmin';
				    	let params = {
				    		admin_id: item.admin_id
				    	};
						that.fd_post(url, params).then((res) => {
				    		if(res.status){
				    			that.$message.success("操作成功");
				    			that.getAdminList();
				    		}else{
				    			that.$message.error(res.msg);
				    		}
				        }).catch((err) => {
				            that.$message.error('网络错误');
				        });
					},300);
				}).catch(() => {
					this.$message.info('已取消删除');
				});
			},
			delAdminLogo() {
				this.addForm.admin_logo = '';
			},
			resetPwd(item){
				this.$confirm('您确定要为'+item.admin_name+'【'+item.account+'】重置密码吗，确定后密码将会重置为【123456】？', '温馨提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					var url = 'admin/resetPwd';
					let params = {
						admin_id: item.admin_id,
					};
					this.fd_post(url, params).then((res) => {
						if(res.status) {
							this.$message.success('密码重置成功');
							this.getAdminList();
						} else {
							this.$message.error(res.msg);
						}
					}).catch((err) => {
						this.$message.error('网络错误');
					});
				}).catch(() => {
					this.$message.info('已取消重置')
				});
			},
			//转换部门数据结构
			normalizer(node) {
				if(node.children && !node.children.length) {
					delete node.children;
				}
				return {
					id: node.dept_id,
					label: node.dept_name,
					children: node.children,
				};
			},
		},
	}
</script>

<style lang="scss" scoped>
	.admin_manage {
		.left {
			text-align: left;
		}
		.mb30 {
			margin-bottom: 30px;
		}
		.trees {
			height: 300px;
			overflow-y: auto;
		}
		.previewUpload {
			display: flex;
			flex-wrap: wrap;
			.previewItems {
				width: 178px;
				height: 178px;
				border: 1px dashed #d9d9d9;
				border-radius: 6px;
				margin: 0 10px 10px 0;
				cursor: pointer;
				position: relative;
				.previewadd {
					font-size: 28px;
					color: #8c939d;
					width: 178px;
					height: 178px;
					line-height: 178px;
					text-align: center;
				}
				&:hover {
					border-color: #409EFF;
				}
			}
			.previewItem {
				width: 178px;
				height: 178px;
				border-radius: 6px;
				margin: 0 10px 10px 0;
				cursor: pointer;
				position: relative;
				.delIcon {
					display: none;
				}
				&:hover {
					.delIcon {
						display: block;
						position: absolute;
						top: 5px;
						right: 5px;
						color: white;
						font-size: 30px;
					}
				}
				img {
					width: 100%;
					height: 100%;
				}
			}
		}
	}
</style>